<template>
  <span>
    <span v-for="h in mapHighlights" :key="h.id">
      <span v-for="child in h.data" :key="child.id">
        <MglMarker
          :coordinates="
            getPos(child.children[0].children)
          "
          v-if="child.children.length"
        >
          <div
            slot="marker"
            :class="['photowalk-thumbnail', 'mapboxgl-marker']"
            @click="
              (e) =>
                onThumbnailClick(
                  child.children[0].children[1].element.id.data.valueId
                )
            "
          >
            <img
              :src="
                'https://api.clay.work/download/' +
                getImage(child) +
                '?Tenant=clay&qualifier=cell'
              "
              :style="{ width: iconWidth, height: iconWidth }"
            />
          </div>
        </MglMarker>
      </span>
    </span>
    <MglMarker :coordinates="cMenuPos" v-if="showCMenu">
      <div slot="marker" class="mapboxgl-marker map-menu">
        <div
          :class="['onmap-menu context', cMenuXPosition, cMenuYPosition]"
          @click="initNewPW"
        >
          New Photowalk
        </div>
      </div>
    </MglMarker>
    <MglMarker :coordinates="cMenuPos" v-if="showDialog">
      <div slot="marker" class="action-icon mapboxgl-marker">
        <div
          :class="[
            'onmap-menu',
            cMenuXPosition,
            cMenuYPosition,
            wait ? 'wait' : '',
          ]"
        >
          <new-photowalk-tab />
          <div class="form-button-container">
            <button
              @click="uploadFiles"
              class="btn btn-primary"
              v-if="showUploadBtn"
            >
              Upload
            </button>
            <button
              class="btn btn-secondary"
              @click="cancelNew"
              v-if="showOKBtn"
            >
              OK
            </button>
            <button class="btn btn-secondary" @click="cancelNew" v-else>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </MglMarker>
  </span>
</template>

<script>
import { MglMarker } from "vue-mapbox";
import { EventBus } from "@/components/EventBus.js";
import { api } from "@/api/api.js";
import _ from "lodash";
import { ASSET_CATEGORY } from "@/components/demo/constants.js";
import NewPhotowalkTab from "./NewPhotowalkTab.vue";

export default {
  name: "MediaAssets",
  props: {
    cId: {
      type: String,
      default: null,
    },
    currentCenter: {
      type: Array,
      default: null,
    },
    currentZoom: {
      type: Number,
      default: null,
    },
    filterItem: {
      type: [Object, Array],
      default: null,
    },
  },
  components: {
    MglMarker,
    NewPhotowalkTab,
  },
  created() {
    const _this = this;
    const thisContext = this.$store.state.contexts.filter(
      (c) => c.value === this.cId
    );
    if (thisContext.length) {
      this.pwName = thisContext[0].text + " pw";
    } else {
      console.error("Context not found" + this.cId);
    }
    const pwContexts = this.$store.state.pwContexts;
    this.pwContext = pwContexts.filter(
      (pw) => pw.text.toLowerCase() === this.pwName.toLowerCase()
    )[0];
    api.getExhibits().then((res) => {
      const contextPwPubs = res.data.menus.filter(
        (e) =>
          e.name.toLowerCase().indexOf(this.pwName.toLowerCase()) > -1 ||
          e.name
            .toLowerCase()
            .indexOf(this.$store.state.activeProject.text.toLowerCase()) > -1
      );
      this.pwPubMenu = _.uniqWith(contextPwPubs, _.isEqual);
      if (thisContext.length) {
        this.getPwPubRoot();
        this.loadContext();
      }
    });
    EventBus.$on("mapRightClick", ($e) => {
      if (_this.cId !== _this.$store.state.activeContext) {
        return;
      }
      if (!_this.pwContext) {
        _this.$bvToast.show("nopwsurface");
        return;
      }
      _this.cMenuXPosition =
        (100 * $e.mapboxEvent.originalEvent.offsetX) / window.innerWidth > 80
          ? "left"
          : "right";
      const yOffset =
        (100 * $e.mapboxEvent.originalEvent.offsetY) / window.innerHeight;
      _this.cMenuYPosition = yOffset > 95 ? "top" : "bottom";
      _this.showCMenu = true;
      _this.showDialog = false;
      _this.cMenuPos = [$e.mapboxEvent.lngLat.lng, $e.mapboxEvent.lngLat.lat];
    });

    EventBus.$on("mapEscKey", () => {
      _this.showCMenu = false;
    });
    EventBus.$on("activeContextChanged", this.activeContextChangedHandler);
    EventBus.$on("filterSelectionChange", (node) => {
      if (node.type !== ASSET_CATEGORY.MEDIA) return;
      if (node.leaf) {
        const isSelected = node.selected;
        const mh = _this.mapHighlights;
        const mhIndex = mh.findIndex((h) => h.type === ASSET_CATEGORY.MEDIA);
        if (mhIndex > -1) {
          // deselect and remove highlight for other media
          mh[mhIndex].selected = false;
          mh.splice(mhIndex, 1);
        }
        if (isSelected) {
          let selected;
          const pwPubRoots = this.pwPubRoots;
          const index = pwPubRoots.findIndex((p) => p.id === node.id);
          if (index > -1) {
            selected = pwPubRoots[index];
            
            const images = selected.data.filter(
              (c) => c.children[0] && c.children[0].children.length > 1
            );
            node.data = images;
            mh.push(node);
            EventBus.$emit("gotoOnMap", {
              coordinates: this.getPos(images[0].children[0].children),
            });
            this.$store.commit("setActiveDetailsTab", "photowalk");
            EventBus.$emit("showDetail", "photowalk", node);
          } else {
            console.log("Error selecting photowalk");
          }
        }
      }
    });
    EventBus.$on("showUploadBtn", () => {
      this.showUploadBtn = true;
    });
    EventBus.$on("uploadComplete", () => {
      this.showUploadBtn = false;
      this.showOKBtn = true;
      this.wait = false;
    });
  },
  computed: {
    iconWidth() {
      const center = this.currentCenter;
      const zoom = this.currentZoom;
      var tmp =
        (65000 * Math.cos((center[1] * Math.PI) / 180)) / Math.pow(2, zoom);
      return (72 * 1.3) / tmp + "px";
    },
  },
  data() {
    return {
      kMenuXPosition: "right",
      kMenuYPosition: "bottom",
      showCMenu: false,
      showDialog: false,
      cMenuPos: [],
      pwName: null,
      pwContext: null,
      pwPubsForFilter: [], // menu
      pwPubRoots: [], // rootcall data
      pwClayPop: [],
      mapHighlights: [],
      showUploadBtn: false,
      showOKBtn: false,
      wait: false,
    };
  },
  methods: {
    initNewPW() {
      // this.$store.commit("setActiveDetailsTab", "newphotowalk");
      // this.$store.state.view = "detailPanelShown";
      this.showCMenu = false;
      this.showDialog = true;
      this.showUploadBtn = false;
      this.showOKBtn = false;
    },
    cancelNew() {
      this.showDialog = false;
    },
    uploadFiles(event) {
      EventBus.$emit("uploadFiles", event);
      this.wait = true;
    },
    getPos(children) {
      const ch = children.filter(
        (ch) => ch.element.content.data.attribute === "geolocation"
      )[0]?.element.content.data;
      if (ch) {

        return [ch.value.longitude, ch.value.latitude];
      } else {
        return [0,0];
      }
    },
    getImage(child) {
      const ch = child.children[0].children.filter(
        (ch) => ch.element.content.data?.value?.href
      );
      return ch[0] ? ch[0].element.content.data.value.href : null;
    },
    getPwPubRoot() {
      // called during create
      const pwClayPop = this.pwClayPop;
      const filterPwPub = this.filterItem.children;
      // filterPwPub.splice(0, filterPwPub.length);
      if (this.pwContext) {
        this.pwPubMenu.forEach((pw) => {
          api.getExhibit(pw.exhibitionId).then((res) => {
            const fi = {
              name: pw.name,
              show: true,
              showChildren: false,
              selected: false,
              categorySelectable: false,
              children: [],
              type: "media",
              category: "knowledge",
              id: pw.exhibitionId,
              leaf: false,
            };
            filterPwPub.push(fi);

            pw.data = res.data.rootNode.children[0].children.forEach(
              (catItem) => {
                const data = catItem.children;
                const id = catItem.element.id.data.valueCellId;
                const name =
                  catItem.children[0].element.content.data.value.name;
                this.pwPubRoots.push({ id: id, name: name, data: data });
                fi.children.push({
                  name: name,
                  show: true,
                  showChildren: false,
                  selected: false,
                  categorySelectable: false,
                  children: [],
                  type: "media",
                  category: "knowledge",
                  id: id,
                  leaf: true,
                });
                this.pwClayPop.push(
                  res.data.rootNode.children[0].children[0].element.ordering
                    .data.position.q
                );
                this.loadContext();
              }
            );
          });
        });
        const ds = api.getContextDataSource(
          this.$store.state.activeProject.value,
          this.pwContext.value,
          localStorage.getItem("tm-user-token")
        );
        ds.addEventListener("message", (event) => {
          // console.log(event);
          const msg = JSON.parse(event.data);
          if (msg.name === "valueCell" || msg.name === "instanceCell") {
            const q = msg.data.position.q;
            const newQ = pwClayPop.indexOf(q) < 0;
            newQ && pwClayPop.push(msg.data.position.q);
          }
        });
      } else {
        console.log("No related Photowalk context");
      }
    },
    loadContext() {
      // const filterItem = this.$props.filterItem;
      // filterItem.splice(0, filterItem.length, ...this.pwPubsForFilter);
      this.$store.state.activePwClayPopulation.length = 0;
      this.$store.state.activePwClayPopulation.push(...this.pwClayPop);
      this.$store.state.activePwCId = this.pwContext
        ? this.pwContext.value
        : null;
    },
    clearContext() {
      this.$store.state.activePwClayPopulation.length = 0;
      this.$store.state.activePwCId = null;
    },
    onThumbnailClick(id) {
      this.$store.state.view = "detailPanelShown";
      EventBus.$emit("viewChange");
      this.$store.commit("setActiveDetailsTab", "photowalk");
      setTimeout(function () {
        document.getElementById(id).scrollIntoView({ behavior: "smooth" });
      }, 500);
    },
    activeContextChangedHandler(context) {
      if (!context) this.clearContext(); // may be called more than once
      else if (context.value === this.cId) this.loadContext();
    },
  },
  destroyed() {
    EventBus.$off("mapRightClick");
    EventBus.$off("mapEscKey");
    EventBus.$off("activeContextChanged", this.activeContextChangedHandler);
    EventBus.$off("filterSelectionChange");
  },
};
</script>

<style>
.onmap-menu.context {
  width: 10vw;
  border: solid 1px white;
  display: inline-block;
  color: white;
  padding: 0.2vw;
  background: #333;
  position: absolute;
  z-index: 9999999;
  cursor: pointer;
}

.onmap-menu.wait {
  cursor: wait;
}
.onmap-menu.right {
  left: 1vw;
}
.onmap-menu.left {
  right: 1vw;
}
.onmap-menu.top {
  bottom: 100%;
}
.photowalk-thumbnail {
  display: flex;
  cursor: pointer;
}
.photowalk-thumbnail img {
  border-radius: 100%;
  width: 100%;
}
.photowalk-tab {
  height: 40vh;
  font: var(--fsize-graphicarea);
}
.form-button-container {
  padding: 0 1vw;
}
</style>