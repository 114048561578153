var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._l((_vm.mapHighlights),function(h){return _c('span',{key:h.id},_vm._l((h.data),function(child){return _c('span',{key:child.id},[(child.children.length)?_c('MglMarker',{attrs:{"coordinates":_vm.getPos(child.children[0].children)}},[_c('div',{class:['photowalk-thumbnail', 'mapboxgl-marker'],attrs:{"slot":"marker"},on:{"click":function (e) { return _vm.onThumbnailClick(
                child.children[0].children[1].element.id.data.valueId
              ); }},slot:"marker"},[_c('img',{style:({ width: _vm.iconWidth, height: _vm.iconWidth }),attrs:{"src":'https://api.clay.work/download/' +
              _vm.getImage(child) +
              '?Tenant=clay&qualifier=cell'}})])]):_vm._e()],1)}),0)}),(_vm.showCMenu)?_c('MglMarker',{attrs:{"coordinates":_vm.cMenuPos}},[_c('div',{staticClass:"mapboxgl-marker map-menu",attrs:{"slot":"marker"},slot:"marker"},[_c('div',{class:['onmap-menu context', _vm.cMenuXPosition, _vm.cMenuYPosition],on:{"click":_vm.initNewPW}},[_vm._v(" New Photowalk ")])])]):_vm._e(),(_vm.showDialog)?_c('MglMarker',{attrs:{"coordinates":_vm.cMenuPos}},[_c('div',{staticClass:"action-icon mapboxgl-marker",attrs:{"slot":"marker"},slot:"marker"},[_c('div',{class:[
          'onmap-menu',
          _vm.cMenuXPosition,
          _vm.cMenuYPosition,
          _vm.wait ? 'wait' : '' ]},[_c('new-photowalk-tab'),_c('div',{staticClass:"form-button-container"},[(_vm.showUploadBtn)?_c('button',{staticClass:"btn btn-primary",on:{"click":_vm.uploadFiles}},[_vm._v(" Upload ")]):_vm._e(),(_vm.showOKBtn)?_c('button',{staticClass:"btn btn-secondary",on:{"click":_vm.cancelNew}},[_vm._v(" OK ")]):_c('button',{staticClass:"btn btn-secondary",on:{"click":_vm.cancelNew}},[_vm._v(" Cancel ")])])],1)])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }